import React from "react"
import Success from "../../components/balondepotrero/modal"

const SuccessPage = ({ data }) => {

  console.log("dataaa", data)

  const successInfo = data.allBdpSuccess.nodes

  return <Success successInfo={successInfo} />
}

export default SuccessPage

export const data = graphql`
  query BbpSuccess {
    allBdpSuccess {
      nodes {
        goldText
        id
        normalText
        link
        who
      }
    }
  }
`
